import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Event from "../components/Event/Event"

export default function EventsPage() {
  return (
    <Layout>
      <SEO title="Events" />
      <Event />
    </Layout>
  )
}
