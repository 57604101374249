import React from "react"
import styles from "./Event.module.scss"

export default function Event(props) {
  return (
    <section className={`min-h-screen ${styles.event}`} {...props}>
      {/*insert image*/}
      <h1 className={styles.eventTitle}>Some Title for Some Event</h1>
      <h2>January 15th, 2020 - 6:00PM to 9:00PM</h2>
      <button className={`mt-4 ${styles.btn} ${styles.btnAccent}`}>
        Sign Up
      </button>
      <div className="mt-4 flex space-x-4">
        <a className={styles.link} href="#">
          Add to Google Calendar
        </a>
        <a className={styles.link} href="#">
          Add to ICal
        </a>
      </div>
      <div className={`mt-4 ${styles.eventContent}`}>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacus
          luctus accumsan tortor posuere ac. Tortor consequat id porta nibh
          venenatis cras sed felis eget. Velit ut tortor pretium viverra
          suspendisse. Facilisi morbi tempus iaculis urna id. At varius vel
          pharetra vel turpis. Phasellus faucibus scelerisque eleifend donec.
          Enim diam vulputate ut pharetra sit. Nam libero justo laoreet sit.
          Lectus magna fringilla urna porttitor rhoncus dolor purus non.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacus
          luctus accumsan tortor posuere ac. Tortor consequat id porta nibh
          venenatis cras sed felis eget. Velit ut tortor pretium viverra
          suspendisse. Facilisi morbi tempus iaculis urna id. At varius vel
          pharetra vel turpis. Phasellus faucibus scelerisque eleifend donec.
          Enim diam vulputate ut pharetra sit. Nam libero justo laoreet sit.
          Lectus magna fringilla urna porttitor rhoncus dolor purus non. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Lacus luctus
          accumsan tortor posuere ac. Tortor consequat id porta nibh venenatis
          cras sed felis eget. Velit ut tortor pretium viverra suspendisse.
          Facilisi morbi tempus iaculis urna id. At varius vel pharetra vel
          turpis. Phasellus faucibus scelerisque eleifend donec. Enim diam
          vulputate ut pharetra sit. Nam libero justo laoreet sit. Lectus magna
          fringilla urna porttitor rhoncus dolor purus non.
        </p>
      </div>
    </section>
  )
}
